import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import styles from "./CertainDayPricesCard.module.scss";
import { getCorrectPrice, getErrorText, setFieldErrorText } from "../../utils/functions";
import { ButtonType, FieldTypes, InputType } from "../../utils/@globalTypes";
import Button from "../Button";
import { CalendarIcon2, DeleteIcon, EditIcon } from "../../assets/icons";
import Input from "../Input";
import Calendar from "../Calendar";
import { format, addMonths } from "date-fns";
import {
  CertainDayPriceStatus,
  ResponseCertainDayPriceType,
  ResponseCertainDayPriceTypeList,
  UpdateCertainDayPricePayloadData,
} from "../../redux/types/pricesTypes";
import { useDispatch, useSelector } from "react-redux";
import {
  createCertainPrice,
  removeCertainDayPrice,
  removeCertainDayPricesStatus,
  setCertainDayPricesStatus,
  updateCertainDayPrice,
} from "../../redux/reducers/pricesSlice";
import { formatDate } from "../Booking/functions";
import classNames from "classnames";
import Loader from "../Loader";
import { AuthSelectors, setErrorResponeData } from "../../redux/reducers/authSlice";
import { priceMask } from "../../utils/constants";

type CertainDayPricesCardProps = {
  rentalId: number;
  data: ResponseCertainDayPriceType;
  status: CertainDayPriceStatus;
  setCertainDayPricesList: React.Dispatch<React.SetStateAction<ResponseCertainDayPriceTypeList>>;  
};

const CertainDayPricesCard: FC<CertainDayPricesCardProps> = ({
  data,
  status,
  setCertainDayPricesList,
  rentalId  
}) => {
  const dispatch = useDispatch();
  
  const errorResponseData = useSelector(AuthSelectors.getErrorResponseData);

  const [price, setPrice] = useState(data.price);

  const [priceError, setPriceError] = useState("");
  const [dateError, setDateError] = useState("");

  const [priceTouched, setPriceTouched] = useState(false);
  const [dateTouched, setDateTouched] = useState(false);

  const [isEdit, setIsEdit] = useState(false);

  const today = new Date();
  const minDate = today;
  const maxDate = addMonths(today, 15);
  const [dateRange, setDateRange] = useState<[Date | undefined, Date | undefined]>([
    data.start_date ? new Date(data.start_date) : undefined,
    data.end_date ? new Date(data.end_date) : undefined,
  ]);
  const [startDate, endDate] = dateRange;
  const placeholder = `${format(minDate, "dd.MM.yyyy")} - ${format(maxDate, "dd.MM.yyyy")}`;
  const date =
    startDate && endDate && `${format(startDate, "dd.MM.yyyy")} - ${format(endDate, "dd.MM.yyyy")}`;

  const clearCalendar = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    setDateRange([undefined, undefined]);
  };

  const onRemoveCertainDayPriceClick = () => {
    if (status.isNew) {
      setCertainDayPricesList((prevState) => prevState.filter((item) => item.id !== data.id));
      dispatch(removeCertainDayPricesStatus(data.id));
    } else {
      dispatch(removeCertainDayPrice(data.id));
    }
  };

  const onToggleIsEditCertainDayPriceClick = () => {
    setPrice(data.price);
    setDateRange([
      data.start_date ? new Date(data.start_date) : undefined,
      data.end_date ? new Date(data.end_date) : undefined,
    ]);
    setIsEdit(!isEdit);
  };

  const onSaveCertainDayPriceClick = () => {
    setDateTouched(true);
    setPriceTouched(true);

    if (startDate && endDate && price && !priceError && !dateError) {
      if (status.isNew) {
        dispatch(
          createCertainPrice({
            id: data.id,
            data: {
              start_date: formatDate(startDate),
              end_date: formatDate(endDate),
              price: price,
              rental_object: rentalId,
            },
          })
        );
      } else {
        const payloadData: UpdateCertainDayPricePayloadData = {};

        const { start_date, end_date, price: old_price } = data;

        start_date !== formatDate(startDate) && (payloadData.start_date = formatDate(startDate));
        end_date !== formatDate(endDate) && (payloadData.end_date = formatDate(endDate));
        +old_price !== +price && (payloadData.price = price);

        dispatch(
          updateCertainDayPrice({
            id: data.id,
            data: payloadData,
          })
        );
      }
    }
  };

  useEffect(() => {
    if (errorResponseData) {
      errorResponseData.start_date &&
        setDateError(
          getErrorText(errorResponseData.start_date[0], "", FieldTypes.CERTAIN_DAY_PRICE)
        );

      dispatch(setErrorResponeData(null));
    }
  }, [errorResponseData]);

  useEffect(() => {
    if (status.isSuccess) {
      if (status.isNew) {
        setCertainDayPricesList((prevState) => prevState.filter((item) => item.id !== data.id));
      } else {
        setIsEdit(!isEdit);
        dispatch(setCertainDayPricesStatus({ ...status, isSuccess: false }));
      }
    }
  }, [status, dispatch, isEdit]);

  useEffect(() => {
    if (dateTouched) {
      if (!startDate || !endDate) {
        setDateError("Поле обязательно для заполнения");
      } else {
        setDateError("");
      }
    }
  }, [dateTouched, startDate, endDate]);

  useEffect(() => {
    setFieldErrorText(priceTouched, price, setPriceError, FieldTypes.DEFAULT, true);
  }, [priceTouched, price]);

  const isValid = useMemo(() => {
    return !priceError && !dateError;
  }, [priceError, dateError]);

  const isFieldsChanged = useMemo(() => {
    if (startDate && endDate) {
      const { start_date, end_date, price: old_price } = data;
      return (
        start_date !== formatDate(startDate) ||
        end_date !== formatDate(endDate) ||
        +old_price !== +price
      );
    }
  }, [startDate, endDate, price, data]);
  
  return (
    <div className={classNames(styles.wrapper,{
      [styles.editMode]:isEdit|| status.isNew,
      [styles.viewMode]:!isEdit
    } )}>
      {status.isLoading && (
        <div className={styles.loaderOverlay}>
          <Loader size={50} />
        </div>
      )}
      <div className={styles.inputWrapper}>
        {isEdit || status.isNew ? (
          <>
            <div className={styles.cardItem}>
              <p className={styles.title}>Период*</p>
              <Calendar
                selectedDate={today}
                maxDate={maxDate}
                minDate={minDate}
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                onChange={(update) => {
                  setDateRange(update);
                }}
                onBlur={setDateTouched}
                inputClassname={classNames(styles.calendar, {
                  [styles.errorInput]: dateError,
                })}
                wrapperInputClassname={styles.cardItem}
                placeholderText={placeholder}
                clearCalendar={clearCalendar}
              />
              {dateError && <p className={styles.errorText}>{dateError}</p>}
            </div>
            <Input
              value={price}
              title="Цена*"
              placeholder="0.00"
              type={InputType.MASK}
              onChange={setPrice}
              errText={priceError}
              onBlur={setPriceTouched}
              mask={priceMask}
              containerClassName={styles.cardItem}
            />
          </>
        ) : (
          <>
            <div className={styles.cardItem}>
              <p className={styles.viewTitle}>Период</p>
              <p className={styles.dateBlock}>
                <CalendarIcon2/>
                {date}
              </p>
            </div>
            <div className={styles.cardItem}>
              <p className={styles.viewTitle}>Цена</p>
              <p className={styles.priceBlock}>{getCorrectPrice(price)}</p>
            </div>
          </>
        )}
      </div>
      <div className={styles.controls}>
        {(isEdit || status.isNew) && (
          <Button
            title="Сохранить"
            type={ButtonType.PRIMARY_SMALL}
            onClick={onSaveCertainDayPriceClick}
            disabled={!isValid || !isFieldsChanged}
            wrapperClassName={isEdit || status.isNew ? styles.saveBtnWrap : styles.viewBtnWrap}
            className={isEdit || status.isNew ? styles.saveBtn : styles.viewBtn}
          />
        )}
        {!status.isNew && (
          <Button
            title={isEdit ? "Отмена" : <EditIcon />}
            type={ButtonType.SECONDARY_SMALL}
            onClick={onToggleIsEditCertainDayPriceClick}
            wrapperClassName={isEdit ? styles.cancelBtnWrap : undefined}
            className={isEdit ? styles.cancelBtn : undefined}
          />
        )}
        <Button
          title={<DeleteIcon />}
          type={ButtonType.SECONDARY_SMALL}
          onClick={onRemoveCertainDayPriceClick}
          confirmWindowText={!status.isNew ? "Действительно хотите удалить?" : undefined}
          windowClassName="bookingConfirm"
        />
      </div>
    </div>
  );
};

export default CertainDayPricesCard;
