import { all, put, takeEvery, takeLatest } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { ApiResponse } from "apisauce";
import callCheckingAuth from "./callCheckingAuth";
import API from "../api";
import { MessageTypes } from "../../utils/@globalTypes";
import { setMessage } from "../reducers/pageSlice";
import {
  addCertainDayPricesStatus,
  addCertianDayPriceInList,
  createCertainPrice,
  removeCertainDayPrice,
  removeCertainDayPriceFromList,
  removeCertainDayPricesStatus,
  setCertainDayPrices,
  setCertainDayPricesStatus,
  setPrices,
  updateCertainDayPrice,
  updateCertainDayPriceInList,
  updatePrices,
} from "../reducers/pricesSlice";
import {
  CertainDayPriceStatus,
  CertianDayPricesResponseData,
  CreateCertainDayPricePayload,
  ResponseCertainDayPriceType,
  ResponsePriceType,
  UpdateCertainDayPricePayload,
  UpdatePricePayload,
} from "../types/pricesTypes";
import { setErrorResponeData } from "../reducers/authSlice";
import { ErrorResponseData } from "../types/@types";

export function* getPricesWorker(action: PayloadAction<number>) {
  const { ok, data, status }: ApiResponse<ResponsePriceType> = yield callCheckingAuth(
    API.getRentalObjectPricesRequest,
    action.payload
  );

  if (data && ok) {
    yield put(
      setPrices({
        ...data,        
      })
    );
  } else {
    if (status) {
      yield put(
        setMessage({
          status: MessageTypes.ERROR,
          message: "Ошибка получения настроек цен/сезонов",
          code: status,
        })
      );
    }
  }
}

function* updatePricesWorker(action: PayloadAction<UpdatePricePayload>) {
  const { id, data } = action.payload;

  const {
    ok,
    data: responseData,
    status,
  }: ApiResponse<ResponsePriceType> = yield callCheckingAuth(
    API.updateRentalObjectPricesRequest,
    id,
    data
  );

  if (responseData && ok) {
    yield put(setPrices(responseData));

    yield put(
      setMessage({
        status: MessageTypes.POSITIVE,
        message: "Изменения сохранены",
        code: 200,
      })
    );
  } else {
    if (status) {
      yield put(
        setMessage({
          status: MessageTypes.ERROR,
          message: "Ошибка обновления настроек цен/сезонов",
          code: status,
        })
      );
    }
  }
}

export function* getCertainDayPricesWorker(action: PayloadAction<number>) {
  const { ok, data, status }: ApiResponse<CertianDayPricesResponseData> = yield callCheckingAuth(
    API.getRentalObjectCertainDayPricesRequest,
    action.payload
  );

  if (data && ok) {
    yield put(setCertainDayPrices(data.results));
  } else {
    if (status) {
      yield put(
        setMessage({
          status: MessageTypes.ERROR,
          message: "Ошибка получения цен на опредленные даты",
          code: status,
        })
      );
    }
  }
}

function* createCertainDayPriceWorker(action: PayloadAction<CreateCertainDayPricePayload>) {
  const { id, data } = action.payload;
  const priceStatus: CertainDayPriceStatus = {
    id,
    isNew: true,
    isLoading: true,
    isSuccess: false,
  };
  yield put(setCertainDayPricesStatus(priceStatus));

  const {
    ok,
    data: responseData,
    status,
  }: ApiResponse<ResponseCertainDayPriceType, ErrorResponseData> = yield callCheckingAuth(
    API.createRentalObjectCertainDayPriceRequest,
    data
  );

  if (responseData && ok) {
    yield put(
      addCertainDayPricesStatus({
        id: responseData.id,
        isNew: false,
        isLoading: false,
        isSuccess: false,
      })
    );
    yield put(addCertianDayPriceInList(responseData));
    yield put(
      setMessage({
        status: MessageTypes.POSITIVE,
        message: "Изменения сохранены",
        code: 200,
      })
    );

    yield put(setCertainDayPricesStatus({ ...priceStatus, isLoading: false, isSuccess: true }));
    yield put(removeCertainDayPricesStatus(id));
  } else {
    if (status) {
      if (responseData && !ok && status === 400) {
        yield put(setErrorResponeData(responseData));
      } else {
        yield put(
          setMessage({
            status: MessageTypes.ERROR,
            message: "Ошибка создания цены на определенную дату",
            code: status,
          })
        );
      }
    }
    yield put(setCertainDayPricesStatus({ ...priceStatus, isLoading: false }));
  }
}

function* removeCertainDayPriceWorker(action: PayloadAction<number>) {
  const priceStatus: CertainDayPriceStatus = {
    id: action.payload,
    isNew: false,
    isLoading: true,
    isSuccess: false,
  };
  yield put(setCertainDayPricesStatus(priceStatus));

  const { ok, status }: ApiResponse<undefined> = yield callCheckingAuth(
    API.removeRentalObjectCertainDayPriceRequest,
    action.payload
  );

  if (ok) {
    yield put(removeCertainDayPriceFromList(action.payload));
  } else {
    if (status) {
      yield put(
        setMessage({
          status: MessageTypes.ERROR,
          message: "Ошибка удаления цены",
          code: status,
        })
      );
    }
  }

  yield put(setCertainDayPricesStatus({ ...priceStatus, isLoading: false }));
}

function* updateCertainDayPriceWorker(action: PayloadAction<UpdateCertainDayPricePayload>) {
  const { id, data } = action.payload;
  const priceStatus: CertainDayPriceStatus = {
    id,
    isNew: false,
    isLoading: true,
    isSuccess: false,
  };
  yield put(setCertainDayPricesStatus(priceStatus));

  const {
    ok,
    data: responseData,
    status,
  }: ApiResponse<ResponseCertainDayPriceType, ErrorResponseData> = yield callCheckingAuth(
    API.updateRentalObjectCertainDayPriceRequest,
    id,
    data
  );

  if (responseData && ok) {
    yield put(updateCertainDayPriceInList(responseData));

    yield put(
      setMessage({
        status: MessageTypes.POSITIVE,
        message: "Изменения сохранены",
        code: 200,
      })
    );

    yield put(setCertainDayPricesStatus({ ...priceStatus, isLoading: false, isSuccess: true }));
  } else {
    if (status) {
      if (responseData && !ok && status === 400) {
        yield put(setErrorResponeData(responseData));
      } else {
        yield put(
          setMessage({
            status: MessageTypes.ERROR,
            message: "Ошибка редактирования цены",
            code: status,
          })
        );
      }
      yield put(setCertainDayPricesStatus({ ...priceStatus, isLoading: false }));
    }
  }
}

export default function* pricesSaga() {
  yield all([
    takeLatest(updatePrices, updatePricesWorker),
    takeLatest(createCertainPrice, createCertainDayPriceWorker),
    takeLatest(removeCertainDayPrice, removeCertainDayPriceWorker),
    takeLatest(updateCertainDayPrice, updateCertainDayPriceWorker),
  ]);
}
