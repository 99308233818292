import React, {useState, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import 'moment/locale/ru';

import styles from "./CheckerboardPrice.module.scss";
import { ArrowDouble, ArrowSingle } from "../../assets/icons";
import Calendar from "../Calendar";
import Button from "../Button";
import { ButtonType, CheckerboardType } from "../../utils/@globalTypes";
import CheckerboardTable from "../CheckerboardTable";
import { RentalObjectSelectors } from "../../redux/reducers/rentalObjectSlice";

type CheckerboardPriceProps ={
  // addPriceHandler: () => void;
}

const CheckerboardPrice = ({}:CheckerboardPriceProps) => {
  const dispatch = useDispatch();
  moment.locale('ru');  
  const currentRentalObject = useSelector(RentalObjectSelectors.getCurrentRentalObject);
  
  const today= moment().startOf('day');
  const startDate=today.clone().subtract(3,'month')
  const endDate=today.clone().add(15,'month')

  const createPeriod =(today:moment.Moment)=> {    
    const period:moment.Moment[]=[]        
    for(let i=startDate; i<=endDate; i=i.clone().add(1,"day")){      
      period.push(i)
    }
    return period
  }
  
  const period= useMemo(()=>createPeriod(today),[today])
   
  const [selectedDate, setSelectedDate] = useState(new Date());  //дата, выбранная на календаре
  const [initialDay, setInitialDay]=useState(today);
  const [isForceUpdateScroll, setIsForceUpdateScroll] = useState<boolean>(false)   //принудительный update скролла, если дата на календаре не менялась и надо к ней вернуться

  const minDate = startDate.toDate();  //ограничительные даты для календаря
  const maxDate = endDate.toDate();
 
  const handlerPrevMonth=()=>{    
    setInitialDay(prevState=> {
      const newDate = prevState.clone().subtract(1, 'month')
      if(newDate>startDate){
        return(
          newDate
        )}else return startDate
      }
    )
    setIsForceUpdateScroll(false)
  }

  const handlerNextMonth=()=>{
    setInitialDay(prevState=> {
      const newDate = prevState.clone().add(1, 'month')
      if(newDate<endDate){
      return(
        newDate
      )}else return endDate
    }
    )
    setIsForceUpdateScroll(false)
  }

  const handlerPrevWeek=()=>{
    setInitialDay(prevState=> {
      const newDate = prevState.clone().subtract(7, 'day')
      if(newDate>startDate){
        return(
          newDate
        )}else return startDate
      }
    )
    setIsForceUpdateScroll(false)
  }

  const handlerNextWeek=()=>{
    setInitialDay(prevState=> {
      const newDate= prevState.clone().add(7, 'day')
      if(newDate<endDate){
        return(
          newDate
        )}else return endDate
      }
  )
  setIsForceUpdateScroll(false)
}

  const handlerToday=()=>{
    setInitialDay(today)
    setIsForceUpdateScroll(true)    
  }
  
  const handlerDatepicker =(date:Date)=> {
    if (date instanceof Date){
      
    setSelectedDate(date);
    setInitialDay(moment(date))

    }else{
      setSelectedDate(new Date());
      setInitialDay(today)
    }
  }
  
  return (
    <section className={styles.container}>
      <div className={styles.buttonPanel}>
        <div className={styles.leftWrapper}>
          <div className={styles.calendarWrap}>
            <div className={styles.calendarButton}> 
              <div className={styles.prev} onClick={handlerPrevMonth}>
                <ArrowDouble/>
                <div className={styles.help}>Предыдущий месяц</div>
              </div>            
              <div className={styles.prev} onClick={handlerPrevWeek}>
                <ArrowSingle/>
                <div className={styles.help}>Предыдущая неделя</div>              
              </div>
            </div>
            <div className={styles.calendar}>
              <Calendar 
                selectedDate={selectedDate}
                onChange={handlerDatepicker}
                minDate={minDate}
                maxDate={maxDate}
                placeholderText="Введите дату"                
              />
            </div>
            <div className={styles.calendarButton}>
              <div className={styles.next} onClick={handlerNextWeek}>
                <ArrowSingle/>
                <div className={styles.help}>Следующая неделя</div>
              </div>
              <div className={styles.next} onClick={handlerNextMonth}>
                <ArrowDouble/>
                <div className={styles.help}>Следующий месяц</div>
              </div>
            </div>
          </div>
          <div className={styles.todayDesk}>
            <Button
              title="Сегодня"
              onClick={handlerToday}
              type={ButtonType.PRIMARY_SMALL}
              className={styles.todayButton}
            />
          </div>
          <div className={styles.todayMobile}>
            <div className={styles.todayWrap}>
              <div className={styles.calendarButton}> 
                <div className={styles.prev} onClick={handlerPrevMonth}>
                  <ArrowDouble/>
                  <div className={styles.help}>Предыдущий месяц</div>
                </div>            
                <div className={styles.prev} onClick={handlerPrevWeek}>
                  <ArrowSingle/>
                  <div className={styles.help}>Предыдущая неделя</div>              
                </div>
              </div>
              <Button
                title="Сегодня"
                onClick={handlerToday}
                type={ButtonType.SECONDARY_SMALL}
                className={styles.todayButton}
              />
              <div className={styles.calendarButton}> 

                <div className={styles.next} onClick={handlerNextWeek}>
                  <ArrowSingle/>
                  <div className={styles.help}>Следующая неделя</div>
                </div>
                <div className={styles.next} onClick={handlerNextMonth}>
                  <ArrowDouble/>
                  <div className={styles.help}>Следующий месяц</div>
                </div>
              </div>
            </div>
          </div>
        </div>      
      </div>
      <div className={styles.tableWrap}>
        <CheckerboardTable 
            period={period} 
            today={today} 
            isForceUpdate={isForceUpdateScroll}
            setForceUpdate = {setIsForceUpdateScroll}
            selectedDate = {selectedDate}
            setSelectedDate = {setSelectedDate}
            initialDay = {initialDay}
            handlerToday ={handlerToday}
            type={CheckerboardType.PRICE}
          />    
      </div>  
    </section>
  );
};

export default CheckerboardPrice;
