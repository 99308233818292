import { retry } from 'redux-saga/effects';
import { ErrorResponseData } from '../../redux/types/@types';
import { OptionsListType } from '../../utils/@globalTypes';
import { BookingFormType, MainObjectShort, BookingStatus } from './../../redux/types/bookingTypes';

export const getTitle = (type:BookingFormType) =>{
  switch (type){
    case BookingFormType.view:
      return "Бронь";
    case BookingFormType.edit:
      return "Бронь";
    case BookingFormType.new:
      return "Новая бронь";
  }
}
export const getSex = (sex:string)=>{
  switch(sex){
    case "Женский":
      return "fem";
    case "Мужской":
      return "mal";
    default:
      return ""    
  }
}

export const createObjectList = (objects:MainObjectShort[])=>{
  const rentalObjectsList:OptionsListType=[];
  if (objects.length>0){
    objects.map(item=>{ 
      if(item.rental_objects&&item.rental_objects.length>0){
      item.rental_objects.map(obj=>{
        
        const rental={
          value:obj.id.toString(),
          label:obj.name
        }
        return rentalObjectsList.push(rental)
        }
      )
      }
    })
    return rentalObjectsList
  }
}

export const statusListOption:OptionsListType = [
  {
    value:"new",
    label: "Новая"
  },
  {
    value:"que",
    label: "В очереди"
  },
  {
    value:"awt",
    label: "Ожидает подтверждения"
  },
  {
    value:"cfm",
    label: "Подтверждена"
  },
  {
    value:"cld",
    label: "Отменена"
  },
]
export const agesListOption:OptionsListType = [
  {
    value:"0",
    label: "до 1 года"
  },
  {
    value:"1",
    label: "1 год"
  },
  {
    value:"2",
    label: "2 года"
  },
  {
    value:"3",
    label: "3 года"
  },
  {
    value:"4",
    label: "4 года"
  },
  {
    value:"5",
    label: "5 лет"
  },
  {
    value:"6",
    label: "6 лет"
  },
  {
    value:"7",
    label: "7 лет"
  },
  {
    value:"8",
    label: "8 лет"
  },
  {
    value:"9",
    label: "9 лет"
  },
  {
    value:"10",
    label: "10 лет"
  },
  {
    value:"11",
    label: "11 лет"
  },
  {
    value:"12",
    label: "12 лет"
  },
  {
    value:"13",
    label: "13 лет"
  },
  {
    value:"14",
    label: "14 лет"
  },
  {
    value:"15",
    label: "15 лет"
  },
  {
    value:"16",
    label: "16 лет"
  },
  {
    value:"17",
    label: "17 лет"
  },
]

export const timeListOption:OptionsListType = [
  {
    value:"00:00",
    label: "00:00"
  },
  {
    value:"00:30",
    label: "00:30"
  },
  {
    value:"01:00",
    label: "01:00"
  },
  {
    value:"01:30",
    label: "01:30"
  },
  {
    value:"02:00",
    label: "02:00"
  },
  {
    value:"02:30",
    label: "02:30"
  },
  {
    value:"03:00",
    label: "03:00"
  },
  {
    value:"03:30",
    label: "03:30"
  },
  {
    value:"04:00",
    label: "04:00"
  },
  {
    value:"04:30",
    label: "04:30"
  },
  {
    value:"05:00",
    label: "05:00"
  },
  {
    value:"05:30",
    label: "05:30"
  },
  {
    value:"06:00",
    label: "06:00"
  },
  {
    value:"06:30",
    label: "06:30"
  },
  {
    value:"07:00",
    label: "07:00"
  },
  {
    value:"07:30",
    label: "07:30"
  },
  {
    value:"08:00",
    label: "08:00"
  },
  {
    value:"08:30",
    label: "08:30"
  },
  {
    value:"09:00",
    label: "09:00"
  },
  {
    value:"09:30",
    label: "09:30"
  },
  {
    value:"10:00",
    label: "10:00"
  },
  {
    value:"10:30",
    label: "10:30"
  },
  {
    value:"11:00",
    label: "11:00"
  },
  {
    value:"11:30",
    label: "11:30"
  },
  {
    value:"12:00",
    label: "12:00"
  },
  {
    value:"12:30",
    label: "12:30"
  },
  {
    value:"13:00",
    label: "13:00"
  },
  {
    value:"13:30",
    label: "13:30"
  },
  {
    value:"14:00",
    label: "14:00"
  },
  {
    value:"14:30",
    label: "14:30"
  },
  {
    value:"15:00",
    label: "15:00"
  },
  {
    value:"15:30",
    label: "15:30"
  },
  {
    value:"16:00",
    label: "16:00"
  },
  {
    value:"16:30",
    label: "16:30"
  },
  {
    value:"17:00",
    label: "17:00"
  },
  {
    value:"17:30",
    label: "17:30"
  },
  {
    value:"18:00",
    label: "18:00"
  },
  {
    value:"18:30",
    label: "18:30"
  },
  {
    value:"19:00",
    label: "19:00"
  },
  {
    value:"19:30",
    label: "19:30"
  },
  {
    value:"20:00",
    label: "20:00"
  },
  {
    value:"20:30",
    label: "20:30"
  },
  {
    value:"21:00",
    label: "21:00"
  },
  {
    value:"21:30",
    label: "21:30"
  },
  {
    value:"22:00",
    label: "22:00"
  },
  {
    value:"22:30",
    label: "22:30"
  },
  {
    value:"23:00",
    label: "23:00"
  },  
  {
    value:"23:30",
    label: "23:30"
  },  
]

export const formatDate = (data:Date) => {
  const year = data.getFullYear();
  const month = data.getMonth();
  const curMonth = month < 9 ? `0${month+1}`: `${month+1}`
  const date = data.getDate();
  const curDate = date < 10 ? `0${date}`: date
  return `${year}-${curMonth}-${curDate}`
}

export const getErrorBookingText = (error:ErrorResponseData)=> {
  switch(error){
    // case 'rental_object: ["Rental object is not available on these dates"]':
    // return "Объект занят в указанные даты"

    default:
      return "Ошибка сохранения"
  }
}

export const getBookingStatusName = (status:string) => {
  switch (status){
    case "new":
      return "Новая бронь";
    case "awt":
      return "Ожидает подтверждения";
    case "cnf":
      return "Бронь подтверждена";
    default:
      return "Новая бронь";
  } 
}