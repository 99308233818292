import * as  Yup  from  'yup' ;
import { REQUIRED_FIELD_ERROR } from '../../utils/constants';

const usePriceValidationSchema = () => {
    
  const validationSchema=Yup.object().shape ({  
    weekday_price:Yup
      .string()
      .required(REQUIRED_FIELD_ERROR),   
    weekend_price:Yup
      .string()    
      .required(REQUIRED_FIELD_ERROR), 
    low_season_weekday_price:Yup
      .string()    
      .required(REQUIRED_FIELD_ERROR),
    low_season_weekend_price:Yup  
      .string()    
      .required(REQUIRED_FIELD_ERROR),
    start_date_month:Yup  
      .string()    
      .required(REQUIRED_FIELD_ERROR),
    start_date_day:Yup  
      .string()    
      .required(REQUIRED_FIELD_ERROR),
    end_date_month:Yup  
      .string()    
      .required(REQUIRED_FIELD_ERROR),
    end_date_day:Yup  
      .string()    
      .required(REQUIRED_FIELD_ERROR)
      // .test('fields-not-equal', 'Нельзя, чтобы все поля были равны', function (values) {
      //   const { start_date_month, start_date_day, end_date_month, end_date_day } = values;
        
      //   // Проверка, чтобы не все поля были равны
      //   return !((start_date_month === end_date_month) && (start_date_day === end_date_day));
      // }),
  }).test('fields-not-equal', 'Нельзя, чтобы все поля были равны', function (values) {
    const { start_date_month, start_date_day, end_date_month, end_date_day } = values;
    
    // Проверка, чтобы не все поля были равны
    return ((start_date_month === end_date_month) && (start_date_day === end_date_day));
  });
  return validationSchema;
}

export default usePriceValidationSchema;
