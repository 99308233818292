// STATE TYPES

import { PayloadDataWithId, ResponseData } from "./@types";

export type PriceType = {
  weekday_price: string;
  weekend_price: string;
  start_date: string;
  end_date: string;
  low_season_weekday_price: string;
  low_season_weekend_price: string;
};

export type PriceEditType = {
  weekday_price: string ;
  weekend_price: string ;
  start_date_month: string ;
  start_date_day: string;
  end_date_month: string ;
  end_date_day: string;
  low_season_weekday_price: string;
  low_season_weekend_price: string ;
};

export type CertainDayPriceType = {
  start_date: string;
  end_date: string;
  price: string;
};

export type CertainDayPriceTypeList = CertainDayPriceType[];

export type CertainDayPriceStatus = {
  id: number;
  isNew: boolean;
  isLoading: boolean;
  isSuccess: boolean;
};

export type CertainDayPriceStatusList = CertainDayPriceStatus[];

// PAYLOAD TYPES

export type UpdatePricePayload = PayloadDataWithId<PriceType>;

export type CreateCertainDayPricePayloadData = {
  start_date: string;
  end_date: string;
  price: string;
  rental_object: number;
};

export type CreateCertainDayPricePayload = PayloadDataWithId<CreateCertainDayPricePayloadData>;

export type UpdateCertainDayPricePayloadData = {
  start_date?: string;
  end_date?: string;
  price?: string;
};

export type UpdateCertainDayPricePayload = PayloadDataWithId<UpdateCertainDayPricePayloadData>;

// RESPONSE TYPES

export type ResponsePriceType = {
  id: number;
  weekday_price: string | null;
  weekend_price: string | null;
  start_date: string | null;
  end_date: string | null;
  low_season_weekday_price: string | null;
  low_season_weekend_price: string | null;
  rental_object: number;
};

export type ResponseCertainDayPriceType = {
  id: number;
  start_date: string;
  end_date: string;
  price: string;
  rental_object: number;
};

export type ResponseCertainDayPriceTypeList = ResponseCertainDayPriceType[];

export type CertianDayPricesResponseData = ResponseData<ResponseCertainDayPriceTypeList>;

export enum PriceVariant { 
  REGULAR_HIGH = "regular_high",
  WEEKEND_HIGH = "weekend_high",  
  REGULAR_LOW = "regular_low",
  WEEKEND_LOW = "weekend_low",  
  CERTAIN = "certain"  
}