
import classNames from "classnames";
import { PriceVariant } from "../../../redux/types/pricesTypes";
import styles from "./PriceBanner.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { PricesSelectors, setActiveCertainPriceId } from "../../../redux/reducers/pricesSlice";

type BannerProps = {
  cellWidth:number,
  isLast:boolean,
  price:{
      summ: number;
      variant: PriceVariant;
      certId: number|undefined
  } 
}
const PriceBanner = ({
  cellWidth,
  isLast,
  price
}:BannerProps) => {
  const dispatch = useDispatch();
  const certainDayPrices = useSelector(PricesSelectors.getCertainDayPrices);

  const {summ, variant, certId} = price;

  const title = variant==="regular_high"
  ? "Высокий сезон, цена в будний день" 
  : variant==="weekend_high"
  ? "Высокий сезон, цена в выходной день"
  : variant==="regular_low" 
  ? "Низкий сезон, цена в будний день"
  : variant==="weekend_low"
  ? "Низкий сезон, цена в выходной день"
  : "Цена на даты"

  let dateView  
  if(variant==="certain"){
    const itemPrice = certainDayPrices.filter(item=> item.id===certId)
    dateView = itemPrice[0].start_date + itemPrice[0].end_date
  } 
  const handlerSearchPrices =() => {
    certId&&dispatch(setActiveCertainPriceId(certId))
  }
  return(
    <div >
      <div className={classNames(styles.container,
        {
          [styles.regular_high]:variant==="regular_high",
          [styles.weekend_high]:variant==="weekend_high",
          [styles.regular_low]:variant==="regular_low",
          [styles.weekend_low]:variant==="weekend_low",
          [styles.certain]:variant==="certain",
        }
      )}
      style={{width:(cellWidth-4), left:(cellWidth/2+2)}}
      >
        {summ.toFixed(2)}
      </div>
      <div className={classNames(styles.inner,
      {
        [styles.regular_high]:variant==="regular_high",
        [styles.weekend_high]:variant==="weekend_high",
        [styles.regular_low]:variant==="regular_low",
        [styles.weekend_low]:variant==="weekend_low",
        [styles.certain]:variant==="certain",
      }
    )}>
        {title} &ensp;
        { variant==="certain" && !isLast &&
        <div className={styles.editBtn} onClick={handlerSearchPrices}>
          перейти
        </div>}
      </div>
    </div>
  )
}

export default PriceBanner