import { TariffOrderType, TariffsInfoType, TariffsValue, TarifTypes} from './../types/@types';
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { getTariffName } from '../../utils/functions';
import { UserTarifType } from '../types/userTypes';

type TariffState = {
  currentTarif: TarifTypes|undefined,
  tariff_exp: string;
  priceList: {
    [TarifTypes.FREE]: TariffsValue,
    [TarifTypes.PRO]: TariffsValue,
    [TarifTypes.PRO_PLUS]: TariffsValue,
    [TarifTypes.START]: TariffsValue 
  },  
  discont:{
    halfYear:number,
    year:number
  },
  tarifFractionDigits:number,
  isPaying:TarifTypes|undefined,
  mainObjectLimit: {
    [TarifTypes.START]: number|null,
    [TarifTypes.PRO]: number|null,
    [TarifTypes.PRO_PLUS]: number|null,
    [TarifTypes.FREE]: number|null 
  },
  rentalObjectLimit: {
    [TarifTypes.START]: number|null,
    [TarifTypes.PRO]: number|null,
    [TarifTypes.PRO_PLUS]: number|null,
    [TarifTypes.FREE]: number|null 
  },

};

const prices = {
  [TarifTypes.FREE]: {
    monthlyPrice:"0.00",
    halfYearPrice:"0.00",
    yearPrice:"0.00"
  },
  [TarifTypes.PRO]: {
    monthlyPrice:"",
    halfYearPrice:"",
    yearPrice:""
  },
  [TarifTypes.PRO_PLUS]: {
    monthlyPrice:"",
    halfYearPrice:"",
    yearPrice:""
  },
  [TarifTypes.START]:{
    monthlyPrice:"",
    halfYearPrice:"",
    yearPrice:""
  }   
}

const initialState: TariffState = {
  currentTarif: undefined,
  tariff_exp:"",
  priceList: prices,
  discont:{
    halfYear:10,
    year:20
  },
  tarifFractionDigits:2,
  isPaying:TarifTypes.PRO,     /// будет отмечен как выгодный

  mainObjectLimit: {
    [TarifTypes.START]: 1,
    [TarifTypes.PRO]: 2,
    [TarifTypes.PRO_PLUS]: Infinity,
    [TarifTypes.FREE]: 1 
  },
  rentalObjectLimit: {
    [TarifTypes.START]: 10,
    [TarifTypes.PRO]: 10,
    [TarifTypes.PRO_PLUS]: Infinity,
    [TarifTypes.FREE]: 1 
  },
};

const TariffsSlice = createSlice({
  name: "tariffs",
  initialState,
  reducers: {
    getTariffsInfo(_, __) {},
    setPriceList(state, action: PayloadAction<TariffsInfoType[]>) {
      action.payload.forEach(item=> {
        const monthlyPrice = (Number(item.cost)).toFixed(state.tarifFractionDigits)
        const halfYearPrice = (Number(item.half_year_cost)).toFixed(state.tarifFractionDigits)
        const yearPrice = (Number(item.year_cost)).toFixed(state.tarifFractionDigits);
        
        const key = getTariffName( item.value)
                        
        state.priceList[key] = {
          monthlyPrice:monthlyPrice,
          halfYearPrice:halfYearPrice,
          yearPrice:yearPrice
        }
      })     
    },
    setOwnerTarif(state,action: PayloadAction<UserTarifType>){
      state.currentTarif=action.payload.value
      state.tariff_exp=action.payload.expired_at
    },
    createFreeTarifOrder(_, __) {},  
    createPaidTarifOrder(_, __: PayloadAction<TariffOrderType>) {},  
}});

export const {
  getTariffsInfo,
  setPriceList,
  setOwnerTarif,
  createFreeTarifOrder,
  createPaidTarifOrder
} = TariffsSlice.actions;

export default TariffsSlice.reducer;
export const tariffsName = TariffsSlice.name;

export const TariffsSelectors = {
  getCurrentTarif: (state: RootState) => state.tariffs.currentTarif,
  getPriceList: (state: RootState) => state.tariffs.priceList,
  getTariffsDiscont: (state: RootState) => state.tariffs.discont,
  getTarifFractionDigits: (state: RootState) => state.tariffs.tarifFractionDigits,
  getPayingTarif: (state: RootState) => state.tariffs.isPaying,
  getTariffExpDate: (state: RootState) => state.tariffs.tariff_exp,
  getMainObjectLimit: (state: RootState) => state.tariffs.mainObjectLimit,
  getRentalObjectLimit: (state: RootState) => state.tariffs.rentalObjectLimit,
};