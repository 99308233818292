import React, { useState } from "react";
import styles from "./Header.module.scss";
import { CalendarIcon2, NotificationIcon, SearchIcon } from "../../../assets/icons";
import Input from "../../../components/Input";
import UserName from "../../../components/UserName";
import { useDispatch, useSelector } from "react-redux";
import { PageSelectors } from "../../../redux/reducers/pageSlice";
import { AuthSelectors, logoutUser } from "../../../redux/reducers/authSlice";
import { getUserType } from "../../../utils/functions";
import { InputType } from "../../../utils/@globalTypes";
import { useNavigate } from "react-router-dom";
import { RoutesList } from "../../../App";
import { UserSelectors } from "../../../redux/reducers/userSlice";
import NotificationSign from "../../../components/Notification";
import { TariffsSelectors } from "../../../redux/reducers/tariffsSlice";
import { TarifTypes } from "../../../redux/types/@types";

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const pageData = useSelector(PageSelectors.getPageData);
  const userData = useSelector(UserSelectors.getOwnerInfo);
  const currentTarif = useSelector(TariffsSelectors.getCurrentTarif)

  const [searchValue, setSearchValue] = useState("");

  const onSearchBtnClick = () => {};

  const onCalendarBtnClick = () => {};

  const DROPDOWN_BUTTONS_LIST = [
    {
      title: "Профиль",
      onClick: () => {
        navigate(RoutesList.settings.fullPath);
      },
    },
    {
      title: "Выйти",
      onClick: () => {
        dispatch(logoutUser());
      },
    },
  ];

  return (
    <div className={styles.wrapper}>
      <div className={styles.titleWrapper}>
        <h1>{pageData?.pageTitle}</h1>
        <p>{pageData?.pageDescription}</p>
      </div>
      <div className={styles.rightControlsWrapper}>
        {/* <div className={styles.searchWrapper}>
          <Input
            value={searchValue}
            placeholder="Введите для поиска"
            className={styles.search}
            type={InputType.TEXT}
            onChange={setSearchValue}
            // onKeyDown={{ key: "Enter", func: onSearchBtnClick }}
          />
          <div className={styles.searchBtn} onClick={onSearchBtnClick}>
            <SearchIcon />
          </div>
        </div> */}
        <div className={styles.headerControls}>
            {/* <div className={styles.headerBtn} onClick={onCalendarBtnClick}>
              <CalendarIcon2 />
            </div> */}         
           {(currentTarif&&currentTarif!==TarifTypes.FREE) && <NotificationSign/>}         
        </div>
        <UserName
          username={!userData ? "Загрузка..." : `${userData.first_name} ${userData.last_name}`}
          jobTitle={!userData ? "" : getUserType(userData.user.status)}
          dropdownList={DROPDOWN_BUTTONS_LIST}
          avatar={userData ? userData.image : null}
        />
      </div>
    </div>
  );
};

export default Header;
