import { useEffect } from "react";
import {
  FIRST_NAME_ERROR,
  EMAIL_ERROR,
  EMAIL_REG,
  NAME_REG,
  OBJECT_NAME_REG,
  REQUIRED_FIELD_ERROR,
  OBJECT_NAME_ERROR,
  URL_REG,
  URL_ERROR,
  PASS_REG,
  PASS_ERROR,
  TELEPHONE_ERROR,
  CONFIRM_PASS_ERROR,
  CREDENTIALS_ERROR,
  INVALID_CODE_ERROR,
  EXPIRED_CODE_ERROR,
  POSITION_REG,
  POSITION_ERROR,
  EMAIL_ALREADY_EXISTS_ERROR,
  MIDDLENAME_REG,
  ADDRESS_ERROR,
  IND_TAXPAYER_NUMBER_REG,
  ENT_TAXPAYER_NUMBER_REG,
  TAXPAYER_NUMBER_ERROR,
  ENTITY_NAME_ERROR,
  IMAGE_ERROR,
  LAST_NAME_ERROR,
  MIDDLE_NAME_ERROR,
  REGION_ERROR,
  LOCALITY_ERROR,
  ADDRESS_REG,
  RENTAL_DESCRIPTION_ERROR,
  FEATURE_NAME_REG,
  FEATURE_NAME_ERROR,
  TOTAL_INFO_ERROR,
  TELEGRAM_REG,
  ROOM_NAME_REG,
  ROOM_NAME_ERROR,
} from "./constants";
import {
  FieldTypes,
  ObjectTypes,
  OptionsListType,
  TimeDataType,
  UserStatusTypes,
} from "./@globalTypes";
import { Value } from "sass";
import { IMask } from "react-imask";
import { TarifTypes } from "../redux/types/@types";

export const useClickOutside = (ref: any, btnRef: any, callback: () => void) => {
  const handleClick = (event: any) => {
    if (
      ref.current &&
      !ref.current.contains(event.target) &&
      btnRef.current &&
      !btnRef.current.contains(event.target)
    ) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);
};

export const getErrorText = (serverError: string, clientError: string, fieldType: FieldTypes) => {
  const REQUIRED_FIELD_SERVER_ERROR = "This field may not be blank.";

  let errorsObj: null | Record<string, string> = null;

  switch (fieldType) {
    case FieldTypes.FIRST_NAME:
    case FieldTypes.LAST_NAME:
      errorsObj = {
        [REQUIRED_FIELD_SERVER_ERROR]: REQUIRED_FIELD_ERROR,
        "It must contain Russian or Latin characters and can have one hyphen.": clientError,
      };
      break;
    case FieldTypes.MIDDLE_NAME:
      errorsObj = {
        "Invalid characters in the text.": clientError,
      };
      break;
    case FieldTypes.IND_TAXPAYER_NUMBER:
      errorsObj = {
        "Enter a valid value.": clientError,
        "Ensure this field has at least 7 characters.": clientError,
        "Ensure this field has no more than 14 characters.": clientError,
      };
      break;
    case FieldTypes.ENT_TAXPAYER_NUMBER:
      errorsObj = {
        "Enter a valid value.": clientError,
        "Ensure this field has at least 8 characters.": clientError,
        "Ensure this field has no more than 14 characters.": clientError,
      };
      break;
    case FieldTypes.EMAIL:
      errorsObj = {
        [REQUIRED_FIELD_SERVER_ERROR]: REQUIRED_FIELD_ERROR,
        "Enter a valid email address.": clientError,
        "User with the same email already exists.": EMAIL_ALREADY_EXISTS_ERROR,
      };
      break;
    case FieldTypes.TELEPHONE:
      errorsObj = {
        [REQUIRED_FIELD_SERVER_ERROR]: REQUIRED_FIELD_ERROR,
        "The phone_number must contain only digits.": clientError,
        "The phone_number must be no more 10 characters long.": clientError,
      };
      break;
    case FieldTypes.OBJECT_NAME:
      errorsObj = {
        [REQUIRED_FIELD_SERVER_ERROR]: REQUIRED_FIELD_ERROR,
        "The name must contain at least 3 characters.": clientError,
        "The name must be no more 60 characters long.": clientError,
        "Rental object with the same name already exists in this main object.":
          "Сдаваемый объект с таким названием уже существует в этом объекте",
        "The name must contain only Latin or Cyrilic characters,numbers and specsymbols (!?@#$%^&*()[]{}<>,._'-+=|/\").":
          clientError,
      };
      break;
    case FieldTypes.ENTITY_NAME:
      errorsObj = {
        [REQUIRED_FIELD_SERVER_ERROR]: REQUIRED_FIELD_ERROR,
        "Ensure this field has at least 3 characters.": clientError,
        "Ensure this field has no more than 60 characters.": clientError,
      };
      break;
    case FieldTypes.URL:
      errorsObj = {
        "Enter a valid URL.": clientError,
      };
      break;
    case FieldTypes.PASSWORD:
      errorsObj = {
        [REQUIRED_FIELD_SERVER_ERROR]: REQUIRED_FIELD_ERROR,
        "This password is too common.": "Этот пароль слишком распространен",
        "The password is too similar to the email.":
          "Пароль слишком похож на адрес электронной почты",
        "This password is entirely numeric.": clientError,
        "The password must contain at least 1 digit, 0-9.": clientError,
        "The password must contain at least 1 uppercase letter, A-Z.": clientError,
        "The password must contain at least 1 lowercase letter, A-Z.": clientError,
        "The password cannot contain Cyrillic characters.": clientError,
        "Ensure this field has at least 8 characters.": clientError,
      };
      break;
    case FieldTypes.CODE:
      errorsObj = {
        [REQUIRED_FIELD_SERVER_ERROR]: REQUIRED_FIELD_ERROR,
        "Invalid confirmation code.": clientError,
        "Ensure this field has no more than 6 characters.": clientError,
        "Verification code has expired.": EXPIRED_CODE_ERROR,
      };
      break;
    case FieldTypes.POSITION:
      errorsObj = {
        [REQUIRED_FIELD_SERVER_ERROR]: REQUIRED_FIELD_ERROR,
        "Ensure this field has at least 3 characters.": clientError,
        "Ensure this field has no more than 50 characters.": clientError,
      };
      break;
    case FieldTypes.PROFILE_PHOTO:
    case FieldTypes.MAIN_OBJECT_PHOTO:
      errorsObj = {
        "Invalid image width. The image width should not be greater than 800 px.": clientError, // -Profile
        "Invalid height width. The image height should not be greater than 600 px.": clientError, // -Profile
        "Invalid image orientation. The width must be greater than the height.": clientError, // -Profile
        "Invalid image format. Allowed formats: jpg, jpeg, png": clientError,
        "The maximum image size must not exceed 1 MB.": clientError,
      };
      break;
    case FieldTypes.MAIN_OBJECT_BOOKING_PHOTO:
      errorsObj = {
        "Invalid image width. The image width should not be greater than 1920 px.": clientError,
        "Invalid height width. The image height should not be greater than 1080 px.": clientError,
        "Invalid image orientation. The width must be greater than the height.": clientError,
        "Invalid image format. Allowed formats: jpg, jpeg, png": clientError,
        "The maximum image size must not exceed 1 MB.": clientError,
      };
      break;
    case FieldTypes.REGION:
      errorsObj = {
        [REQUIRED_FIELD_SERVER_ERROR]: REQUIRED_FIELD_ERROR,
        "The region must contain at least 3 characters.": clientError,
        "The region must be no more 60 characters long.": clientError,
      };
      break;
    case FieldTypes.LOCALITY:
      errorsObj = {
        [REQUIRED_FIELD_SERVER_ERROR]: REQUIRED_FIELD_ERROR,
        "The locality must contain at least 3 characters.": clientError,
        "The locality must be no more 60 characters long.": clientError,
      };
      break;
    case FieldTypes.ADDRESS:
      errorsObj = {
        [REQUIRED_FIELD_SERVER_ERROR]: REQUIRED_FIELD_ERROR,
        "The address must contain at least 3 characters.": clientError,
        "The address must be no more 60 characters long.": clientError,
      };
      break;
    case FieldTypes.COUNTRY:
    case FieldTypes.OBJECT_TYPE:
    case FieldTypes.PERMISSION:
      errorsObj = {
        [REQUIRED_FIELD_SERVER_ERROR]: clientError,
      };
      break;
    case FieldTypes.RENTAL_OBJECT:
      errorsObj = {
        [REQUIRED_FIELD_SERVER_ERROR]: REQUIRED_FIELD_ERROR,
        "Rental object is not available on these dates": "Объект занят в указанные даты",
      };
      break;
    case FieldTypes.CERTAIN_DAY_PRICE:
      errorsObj = {
        [REQUIRED_FIELD_SERVER_ERROR]: REQUIRED_FIELD_ERROR,
        "Date has wrong format. Use one of these formats instead: YYYY-MM-DD.":
          REQUIRED_FIELD_ERROR,
        "The dates overlap with existing dates ranges.":
          "Даты пересекаются с существующими диапазонами дат",
        "Unable to select past date.": "Невозможно выбрать прошедшую дату",
      };
      break;
    case FieldTypes.DEFAULT:
      errorsObj = {
        "No active account found with the given credentials": clientError,
      };
      break;
    default:
      break;
  }

  return errorsObj?.[serverError] || "Ошибка поля";
};

export const getUserType = (userType?: string) => {
  switch (userType) {
    case UserStatusTypes.OWNER:
      return "Владелец";
    case UserStatusTypes.STAFF:
      return "Персонал";
    case UserStatusTypes.ADMIN:
      return "Администратор";
    default:
      return "";
  }
};

export const setFieldRequiredErrorText = (
  touched: boolean,
  fieldValue: string,
  setFieldValue: (value: string) => void
) => {
  if (touched) {
    if (fieldValue.length === 0) {
      setFieldValue(REQUIRED_FIELD_ERROR);
    } else {
      setFieldValue("");
    }
  }
};

export const setFieldErrorText = (
  touched: boolean,
  fieldValue: string,
  setFieldError: (value: string) => void,
  fieldType: FieldTypes,
  required?: boolean,
  condition?: boolean,
  setFieldFocus?: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const isEmpty = fieldValue.length === 0;

  if (touched) {
    if (required && isEmpty) {
      setFieldError(REQUIRED_FIELD_ERROR);
    } else {
      switch (fieldType) {
        case FieldTypes.FIRST_NAME:
          if (!NAME_REG.test(fieldValue) || fieldValue.length > 60) {
            setFieldError(FIRST_NAME_ERROR);
          } else {
            setFieldError("");
          }
          break;
        case FieldTypes.LAST_NAME:
          if (!NAME_REG.test(fieldValue) || fieldValue.length > 60) {
            setFieldError(LAST_NAME_ERROR);
          } else {
            setFieldError("");
          }
          break;
        case FieldTypes.MIDDLE_NAME:
          if ((!MIDDLENAME_REG.test(fieldValue) && !isEmpty) || fieldValue.length > 60) {
            setFieldError(MIDDLE_NAME_ERROR);
          } else {
            setFieldError("");
          }
          break;
        case FieldTypes.EMAIL:
          if (!EMAIL_REG.test(fieldValue)) {
            setFieldError(EMAIL_ERROR);
          } else {
            setFieldError("");
            if (setFieldFocus) setFieldFocus(false);
          }
          break;
        case FieldTypes.TELEGRAM:
          if (!TELEGRAM_REG.test(fieldValue)) {
            setFieldError(TOTAL_INFO_ERROR);
          } else {
            setFieldError("");
            if (setFieldFocus) setFieldFocus(false);
          }
          break;
        case FieldTypes.OBJECT_NAME:
          if (
            !OBJECT_NAME_REG.isLetter.test(fieldValue) ||
            !OBJECT_NAME_REG.length.test(fieldValue)
          ) {
            setFieldError(OBJECT_NAME_ERROR);
          } else {
            setFieldError("");
          }
          break;
        case FieldTypes.REGION:
          if (
            (!ADDRESS_REG.isLetter.test(fieldValue) || !ADDRESS_REG.length.test(fieldValue)) &&
            !isEmpty
          ) {
            setFieldError(REGION_ERROR);
          } else {
            setFieldError("");
          }
          break;
        case FieldTypes.LOCALITY:
          if (
            (!ADDRESS_REG.isLetter.test(fieldValue) || !ADDRESS_REG.length.test(fieldValue)) &&
            !isEmpty
          ) {
            setFieldError(LOCALITY_ERROR);
          } else {
            setFieldError("");
          }
          break;
        case FieldTypes.ADDRESS:
          if (
            (!ADDRESS_REG.isLetter.test(fieldValue) || !ADDRESS_REG.length.test(fieldValue)) &&
            !isEmpty
          ) {
            setFieldError(ADDRESS_ERROR);
          } else {
            setFieldError("");
          }
          break;
        case FieldTypes.IND_TAXPAYER_NUMBER:
          if (
            !IND_TAXPAYER_NUMBER_REG.isDigit.test(fieldValue) ||
            !IND_TAXPAYER_NUMBER_REG.length.test(fieldValue)
          ) {
            setFieldError(TAXPAYER_NUMBER_ERROR);
          } else {
            setFieldError("");
          }
          break;
        case FieldTypes.ENT_TAXPAYER_NUMBER:
          if (!ENT_TAXPAYER_NUMBER_REG.test(fieldValue)) {
            setFieldError(TAXPAYER_NUMBER_ERROR);
          } else {
            setFieldError("");
          }
          break;
        case FieldTypes.ENTITY_NAME:
          if (
            !OBJECT_NAME_REG.isLetter.test(fieldValue) ||
            !OBJECT_NAME_REG.length.test(fieldValue)
          ) {
            setFieldError(ENTITY_NAME_ERROR);
          } else {
            setFieldError("");
          }
          break;
        case FieldTypes.URL:
          if (!URL_REG.test(fieldValue) && !isEmpty) {
            setFieldError(URL_ERROR);
          } else {
            setFieldError("");
          }
          break;
        case FieldTypes.PASSWORD:
          if (
            !PASS_REG.isUppercaseLetter.test(fieldValue) ||
            !PASS_REG.isLowercaseLetter.test(fieldValue) ||
            !PASS_REG.isDigit.test(fieldValue) ||
            !PASS_REG.minLength.test(fieldValue) ||
            !PASS_REG.isSymbol.test(fieldValue)
          ) {
            setFieldError(PASS_ERROR);
          } else {
            setFieldError("");
          }
          break;
        case FieldTypes.CONFIRM_PASSWORD:
          if (condition && !isEmpty) {
            setFieldError(CONFIRM_PASS_ERROR);
          } else {
            setFieldError("");
          }
          break;
        case FieldTypes.TELEPHONE:
          if (condition && !isEmpty) {
            setFieldError(TELEPHONE_ERROR);
          } else {
            setFieldError("");
          }
          break;
        case FieldTypes.POSITION:
          if (!POSITION_REG.isLetter.test(fieldValue) || !POSITION_REG.length.test(fieldValue)) {
            setFieldError(POSITION_ERROR);
          } else {
            setFieldError("");
          }
          break;
        case FieldTypes.RENTAL_DESCRIPTION:
          if (fieldValue.length > 550) {
            setFieldError(RENTAL_DESCRIPTION_ERROR);
          } else {
            setFieldError("");
          }
          break;
        case FieldTypes.FEATURE_NAME:
          if (
            !FEATURE_NAME_REG.isLetter.test(fieldValue) ||
            !FEATURE_NAME_REG.length.test(fieldValue)
          ) {
            setFieldError(FEATURE_NAME_ERROR);
          } else {
            setFieldError("");
          }
          break;
        case FieldTypes.ROOM_NAME:
          if (
            (!ROOM_NAME_REG.isLetter.test(fieldValue) || !ROOM_NAME_REG.length.test(fieldValue)) &&
            !isEmpty
          ) {
            setFieldError(ROOM_NAME_ERROR);
          } else {
            setFieldError("");
          }
          break;
        // case FieldTypes.DEFAULT:
        //   setFieldError("");
        //   break;
        default:
          setFieldError("");
          break;
      }
    }
  }
};

export const getFormattedName = (str: string) => {
  const newStr = str
    .split("-")
    .map((word) => {
      if (word.length > 0) {
        return word[0].toUpperCase() + word.slice(1).toLocaleLowerCase();
      }
    })
    .join("-");
  return newStr;
};

export const getFormattedPhone = (countryCode: string, value: string) => {
  return value.length > 0 ? `+${countryCode}${value}` : undefined;
};

export const setTimer = (
  length: number,
  setIsStartTimer: (value: boolean) => void,
  setTimerId?: (value: any) => void,
  setTime?: React.Dispatch<React.SetStateAction<TimeDataType>>,
  isOneMinuteInterval?: boolean
) => {
  setIsStartTimer(true);
  const start = new Date().getTime();
  let step = 0;
  let timeLength = length;
  let elapsed = 0;
  let isFirst = true;

  function instance() {
    step += 1000;
    timeLength -= 1;
    elapsed = Math.floor(step / 1000);

    if (setTime) {
      const days = checkTimeCharLength(Math.trunc(timeLength / 60 / 60 / 24));
      const hours = checkTimeCharLength(Math.trunc((timeLength / 60 / 60) % 24));
      const minutes = checkTimeCharLength(Math.trunc((timeLength / 60) % 60));
      const seconds = checkTimeCharLength(Math.trunc(timeLength % 60));
      // console.log(days);
      const setTimeFunc = () => {
        setTime({
          days: { char1: days[0], char2: days[1] },
          hours: { char1: hours[0], char2: hours[1] },
          minutes: { char1: minutes[0], char2: minutes[1] },
          seconds: { char1: seconds[0], char2: seconds[1] },
        });
      };

      if (isOneMinuteInterval) {
        isFirst && setTimeFunc();

        isFirst = false;
        console.log(1);

        length % 60 === 0 && setTimeFunc();
      } else {
        setTimeFunc();
      }
    }

    if (elapsed === length) {
      setIsStartTimer(false);
      return;
    }

    const diff = new Date().getTime() - start - step;
    id = setTimeout(instance, 1000 - diff);
  }

  let id = setTimeout(instance, 1000);
  setTimerId && setTimerId(id);
};

export const checkTimeCharLength = (time: number) => {
  return time.toString().length === 1 ? `0${time}` : time.toString();
};

export const getFormattedTime = (time: number) => {
  const minutes = Math.trunc(time / 60);
  const seconds = time % 60;

  const formattedMin = `${minutes} мин.`;
  const formattedSec = `${seconds} сек.`;

  return `${minutes !== 0 ? formattedMin : ""} ${seconds !== 0 ? formattedSec : ""}`;
};

export const getInflectedObjectTypes = (value: string) => {
  switch (value) {
    case ObjectTypes.FARMSTEAD:
      return "Усадьбы/Агроусадьбы";
    case ObjectTypes.APARTMENTS:
      return "Квартиры/Апартаментов";
    case ObjectTypes.GUEST_HOUSE:
      return "Гостевого дома";
    case ObjectTypes.CAMPING:
      return "Кемпинга";
    case ObjectTypes.GLAMPING:
      return "Глэмпинга";
    case ObjectTypes.MINI_HOTEL:
      return "Мини-отеля";
    case ObjectTypes.TOURIST_COMPLEX:
      return "Туристического комплекса";
    case ObjectTypes.RECREATION_CENTER:
      return "Базы отдыха";
    default:
      return "объекта";
  }
};

export const banDoubleSpace = (value: string) => {
  return value.replace(/^\s/, "").replace(/\s\s/g, " ");
};

export const getCurrentYearMonthOptions = () => {
  const now = new Date();
  const monthWithDates: Record<string, OptionsListType> = {};
  for (let i = 0; i < 12; i++) {
    const daysCount = 33 - new Date(now.getFullYear(), i, 33).getDate();
    const correctMonthNumber = i + 1;
    const key = correctMonthNumber < 10 ? "0" + correctMonthNumber : correctMonthNumber;
    monthWithDates[key] = [];
    for (let j = 1; j <= daysCount; j++) {
      const dayKey = j < 10 ? "0" + j : j;
      monthWithDates[key].push({
        value: dayKey.toString(),
        label: j.toString(),
      });
    }
  }

  return monthWithDates;
};

export function getFormattedDateWithMonthAndDay(
  month: number | "XX",
  day: number | "XX",
  separator: string = "-"
) {
  let formattedDay = day === "XX" ? "XX" : day < 10 ? "0" + day : day.toString();
  let formattedMonth = month === "XX" ? "XX" : month < 10 ? "0" + month : month.toString();

  return `${formattedMonth}${separator}${formattedDay}`;
}

// Преобразование файлов в base64
export const convertFileToBase64 = (file: File) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
};

// MASKS

export const getCorrectPrice = (price: string, currency: string = "BYN") => {
  const numberMask = IMask.createMask({
    mask: Number,
    scale: 2,
    signed: false,
    thousandsSeparator: " ",
    padFractionalZeros: true,
    radix: ".",
    mapToRadix: ["."],
  });

  numberMask.resolve(price);
  return `${numberMask.value} ${currency}`;
};

export const getTariffName = (value: string) => {
  switch (value) {
    case "FREE":
      return TarifTypes.FREE;
    case "PRO":
      return TarifTypes.PRO;
    case "PRO_PLUS":
      return TarifTypes.PRO_PLUS;
    case "START":
      return TarifTypes.START;
    default:
      return TarifTypes.PRO_PLUS;
  }
};

// преобразуем цены с бэка для библиотеки Formik
export const unformatNumber = (number:string) => {
  let formattedNumber:string = number
  if (number.indexOf(".")>0){
    if(number.endsWith(".00")){
      formattedNumber = number.substring(0, number.length-3)
    } else if (number.endsWith("0")) {
      formattedNumber = number.substring(0, number.length-1)
    }

  } 
  return formattedNumber
}