import { useRef, useState } from 'react';
import { Formik, Form, FormikProps } from  'formik' ;
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { PriceEditType, PriceType, ResponsePriceType } from "../../redux/types/pricesTypes";
import { ButtonType, TabsNames } from "../../utils/@globalTypes";
import usePriceValidationSchema from "./priceValidationSchema";
import { unformatNumber } from '../../utils/functions';
import Button from '../Button';
import { RoutesList } from '../../App';
import { updatePrices } from '../../redux/reducers/pricesSlice';
import styles from "./RentalPriceSettings.module.scss";
import SettingFormInner from './SettingFormInner';
import ScrollToError from '../Booking/ScrollToError';

type PriceSettings = {
  prices:ResponsePriceType | null
}

const RentalPriceSettings = ({prices}:PriceSettings) => {
  const { mainId } = useParams();
  const dispatch = useDispatch();
  const validationSchema = usePriceValidationSchema();

  const [commonError, setCommonError] = useState<string>("")
  
  const initialValues:PriceEditType={
    weekday_price: prices?.weekday_price && unformatNumber(prices?.weekday_price)|| "",
    weekend_price: prices?.weekend_price && unformatNumber(prices?.weekend_price )|| "",
    low_season_weekday_price: prices?.low_season_weekday_price && unformatNumber(prices?.low_season_weekday_price)|| "",
    low_season_weekend_price: prices?.low_season_weekend_price && unformatNumber(prices?.low_season_weekend_price)|| "",
    start_date_month: prices?.start_date?.slice(0,2) || "",
    start_date_day: prices?.start_date?.slice(3) || "",
    end_date_month: prices?.end_date?.slice(0,2) || "",
    end_date_day: prices?.end_date?.slice(3) || ""
  }
  const formikRef = useRef<FormikProps<PriceEditType>>(null);  

  return (
    <Formik 
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnBlur = {false}          
      validateOnChange ={false}   
      validateOnMount ={false} 
      innerRef={formikRef}
      onSubmit={(values)=>{
        const updatePrice:PriceType ={
          weekday_price:values.weekday_price,
          weekend_price:values.weekend_price,
          low_season_weekday_price:values.low_season_weekday_price,
          low_season_weekend_price:values.low_season_weekend_price,
          start_date: values.start_date_month + "-" + values.start_date_day,
          end_date: values.end_date_month + "-" + values.end_date_day 
        }
        try {
          if (JSON.stringify(initialValues) != JSON.stringify(values) 
            && prices?.id && !commonError){              
          dispatch(
            updatePrices({
              id: prices.id,
              data: updatePrice,
            })
          )} 
    
        } catch (error) {
          console.error('Ошибка отправки:', error);
        }
      }}>
        {(formik) => (
        <Form>
          <div className={styles.currency}>
            <div className={styles.currencyTitle}>Цены указываются в BYN</div>        
          </div>

          <SettingFormInner 
            formik={formik}
            commonError= {commonError}
            setCommonError = {setCommonError}/>
          
          <div className={styles.mainBtnsWrapper}>
            <Button
              title="Сохранить"
              type={ButtonType.PRIMARY_SMALL}
              actionType="submit"
              disabled={!formik.dirty }
              className={styles.mainBtn}
            />
            <Button
              title={false ? "Отмена" : "Назад"}
              type={ButtonType.SECONDARY_SMALL}
              path={`${RoutesList.objectManagement.objectEditingChild.fullPath}/${mainId}/${TabsNames.OBJECT_EDITING}`}
              className={styles.mainBtn}
            />
          </div>
          <ScrollToError />        
        </Form>
       )}
    </Formik>
  )
}

export default RentalPriceSettings