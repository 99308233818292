import { addYears, endOfDay, startOfDay, parseISO } from "date-fns";
import { CurrentRentalObject } from "../redux/types/rentalObjectTypes"
import { PriceType, PriceVariant, ResponseCertainDayPriceTypeList, ResponsePriceType } from "../redux/types/pricesTypes";


// считаем стоимость проживания за период / или 1 день
export const priceCalculator = (prices:ResponsePriceType|PriceType, certainDayPrices:ResponseCertainDayPriceTypeList, period:Date[]) =>{
  const currentYear = new Date().getFullYear();
  const prevYear = currentYear-1

  let startHighSeason = prices.start_date&&new Date(prices?.start_date + "-" + currentYear.toString());   // добавляем года к настройкам начала и конца высокого сезона  
  let endHighSeason = prices.end_date&&new Date(prices.end_date + "-" + currentYear.toString());
  
  let anotheYearStartHighSeason:Date;
  let anotheYearEndHighSeason:Date;
  let variant:PriceVariant = PriceVariant.REGULAR_HIGH
  let certId:number|undefined;
  
  if (startHighSeason&&endHighSeason){      
    
    if(startHighSeason>endHighSeason){
      startHighSeason.setFullYear(prevYear);
      endHighSeason.setFullYear(currentYear);          
    }
    anotheYearStartHighSeason=addYears(startHighSeason, 1)
    anotheYearEndHighSeason=addYears(endHighSeason, 1)
  }
  let summ:number = 0;  // общая сумма
    period.forEach(item=>{   //считаем стоимость каждого дня
      let priceDay = 0
      const day = item.getDay();   //определяем день недели
      
      if(startHighSeason&&endHighSeason){    
        if ((item>=startHighSeason && item<=endHighSeason)||(item>=anotheYearStartHighSeason && item<=anotheYearEndHighSeason)){
          // цена высокого сезона
          if(day===5||day===6){
            priceDay = Number(prices.weekend_price)
            variant = PriceVariant.WEEKEND_HIGH
          } else {
            priceDay = Number(prices.weekday_price)
            variant = PriceVariant.REGULAR_HIGH
          }
          
        } else {   // цена низкого сезона
          if(day===5||day===6){
            priceDay = Number(prices.low_season_weekend_price)
            variant = PriceVariant.WEEKEND_LOW
          } else {
            priceDay = Number(prices.low_season_weekday_price)
            variant = PriceVariant.REGULAR_LOW
          }
        }
      }
      if(certainDayPrices && certainDayPrices.length>0){
        certainDayPrices.forEach(certItem=>{
          if(item>=startOfDay(new Date(certItem.start_date)) && item<=endOfDay(new Date(certItem.end_date))){
            priceDay=Number(certItem.price)   
            variant=PriceVariant.CERTAIN
            certId = certItem.id               
          }
        })        
      }
      summ= summ+priceDay      
    })
    
    const answer = {
      summ:summ,
      variant:variant,
      certId:certId
    }
  return answer
}