import { useEffect } from "react";
import { FormikProps } from "formik";
import { priceMask } from "../../../utils/constants";
import { PriceEditType } from "../../../redux/types/pricesTypes";
import { InputType, OptionsListType } from "../../../utils/@globalTypes";
import { getCurrentYearMonthOptions } from "../../../utils/functions";
import Input from "../../Input";
import SelectComponentV2 from "../../SelectComponentV2";

import styles from "./SettingFormInner.module.scss";

const MonthOptions: OptionsListType = [
  { value: "01", label: "Январь" },
  { value: "02", label: "Февраль" },
  { value: "03", label: "Март" },
  { value: "04", label: "Апрель" },
  { value: "05", label: "Май" },
  { value: "06", label: "Июнь" },
  { value: "07", label: "Июль" },
  { value: "08", label: "Август" },
  { value: "09", label: "Сентябрь" },
  { value: "10", label: "Октябрь" },
  { value: "11", label: "Ноябрь" },
  { value: "12", label: "Декабрь" },
];
const currentYearMonth = getCurrentYearMonthOptions();

type SettingFormProps ={
  formik:FormikProps<PriceEditType>,  
  commonError:string,
  setCommonError: React.Dispatch<React.SetStateAction<string>>
}
const SettingFormInner = ({
  formik, 
  commonError, 
  setCommonError}:SettingFormProps) => {
  const {values, touched, errors, handleChange, 
    setFieldTouched, setFieldValue, validateField} = formik;

useEffect(() => {
  if(touched.weekday_price){
    validateField("weekday_price")
  };
  if(touched.weekend_price){
    validateField("weekend_price")
  };
  if(touched.low_season_weekday_price){
    validateField("low_season_weekday_price")
  };
  if(touched.low_season_weekend_price){
    validateField("low_season_weekend_price")
  };
  if(touched.start_date_month){
    validateField("start_date_month")
  };
  if(touched.start_date_day){
    validateField("start_date_day")
  };
  if(touched.end_date_month){
    validateField("end_date_month")
  };
  if(touched.end_date_day){
    validateField("end_date_day")
  };
}, [touched, values]);

useEffect(() => {
  if (values.start_date_month && (values.start_date_month===values.end_date_month) &&
    values.start_date_day && (values.start_date_day===values.end_date_day)
    ){
      setCommonError("Даты начала и окончания не должны совпадать")      
    } else setCommonError("")
    
}, [values]); 
     
  return(
    <>
    <h3 className={styles.contentTitle}>Настройки высокого сезона</h3>
        <div className={styles.dates}>          
          <div>
            <p className={styles.label}>Дата начала высокого сезона* (дата заезда)</p>
            <div className={styles.date}>
              <SelectComponentV2
                id="start_date_month"
                title="Месяц"
                placeholder="—"
                optionsList={MonthOptions}
                currentValue={values.start_date_month }
                onBlur={() => setFieldTouched("start_date_month")}
                setSelecValue={(value)=>{
                  setFieldValue("start_date_month", value);
                  setFieldValue("start_date_day", "")
                  }}
                className={styles.month}
                errText={errors.start_date_month || (commonError&& " ")}
              />
              <SelectComponentV2
                id="start_date_day"
                title="День"
                placeholder="—"
                optionsList={currentYearMonth[values.start_date_month]}
                currentValue={values.start_date_day}
                setSelecValue={handleChange("start_date_day")}
                maxMenuHeight={200}
                onBlur={() => setFieldTouched("start_date_day")}
                className={styles.day}
                errText={errors.start_date_day || (commonError&& " ")}
                isDisabled={!values.start_date_month}
              />
            </div>
            {commonError && <p className={styles.errorText}>{commonError}</p>}
          </div>
          <div>
            <p className={styles.label}>Дата окончания высокого сезона* (дата заезда)</p>
            <div className={styles.date}>
              <SelectComponentV2
                id="end_date_month"
                title="Месяц"
                placeholder="—"
                optionsList={MonthOptions}
                currentValue={values.end_date_month}
                setSelecValue={(value)=>{
                  setFieldValue("end_date_month", value);
                  setFieldValue("end_date_day", "")
                  }}
                onBlur={() => setFieldTouched("end_date_month")}
                className={styles.month}
                errText={errors.end_date_month || (commonError&&" ")}
              />
              <SelectComponentV2
                id="end_date_day"
                title="День"
                placeholder="—"
                optionsList={currentYearMonth[values.end_date_month]}
                currentValue={values.end_date_day}
                setSelecValue={handleChange("end_date_day")}
                maxMenuHeight={200}
                onBlur={() => setFieldTouched("end_date_day")}
                className={styles.day}
                errText={errors.end_date_day || (commonError&& " ")}
                isDisabled={!values.end_date_month}
              />
            </div>
            {commonError && <p className={styles.errorText}>{commonError}</p>}
          </div>
        </div>
    <h3 className={styles.contentTitle}>Цены проживания в высокий сезон</h3> 
      <div className={styles.settingsFields}>
        <div>               
          <div className={styles.prices}>
            <Input
              id="weekday_price"
              name="weekday_price"
              value={values.weekday_price}
              title="Цена в будние дни (за сутки)*"
              placeholder="0.00"
              type={InputType.MASK}
              onChange={handleChange("weekday_price")}
              errText={errors.weekday_price}
              onBlur={() => setFieldTouched("weekday_price")}
              containerClassName={styles.field}
              mask={priceMask}
            />
            <Input
              id="weekend_price"
              name="weekend_price"
              value={values.weekend_price}
              title="Цена в выходные дни (за сутки)*"
              placeholder="0.00"
              type={InputType.MASK}
              onChange={handleChange("weekend_price")}
              errText={errors.weekend_price}
              onBlur={() => setFieldTouched("weekend_price")}
              containerClassName={styles.field}
              mask={priceMask}
            />     
          </div>
          <h3 className={styles.contentTitle}>Цены проживания в низкий сезон</h3>
          <div className={styles.prices}>
            <Input
              id="low_season_weekday_price"
              name="low_season_weekday_price"
              value={values.low_season_weekday_price }
              title="Цена в будние дни (за сутки)*"
              placeholder="0.00"
              type={InputType.MASK}
              onChange={handleChange("low_season_weekday_price")}
              errText={errors.low_season_weekday_price}
              onBlur={() => setFieldTouched("low_season_weekday_price")}
              containerClassName={styles.field}
              mask={priceMask}
            />
            <Input
              id="low_season_weekend_price"
              name="low_season_weekend_price"
              value={values.low_season_weekend_price}
              title="Цена в выходные дни (за сутки)*"
              placeholder="0.00"
              type={InputType.MASK}
              onChange={handleChange("low_season_weekend_price")}
              errText={errors.low_season_weekend_price}
              onBlur={() => setFieldTouched("low_season_weekend_price")}
              containerClassName={styles.field}
              mask={priceMask}              
            />
          </div>
        </div>        
      </div>
    </>
  )
}

export default SettingFormInner;
