import { FC } from "react";
import styles from './CheckerboardCell.module.scss';
import CheckerboardBanner from "../CheckerboardBanner";
import classNames from "classnames";
import { BookingType } from "../../redux/types/bookingTypes";
import moment from "moment";
import PriceBanner from "../CheckerboardPrice/PriceBanner";
import { PriceVariant } from "../../redux/types/pricesTypes";

type CheckerboardCellProps= {
  itemWidth:number,
  id:number|string,
  booking?:BookingType | undefined,
  longBooking?: BookingType | null | undefined,
  index:number,
  day:moment.Moment,
  isToday: boolean,
  isSelected: boolean,
  isWeekend: boolean,
  price?: {
    summ: number;
    variant: PriceVariant;
    certId: number|undefined
} | null
  isPrice?:boolean
}

const CheckerboardCell:FC<CheckerboardCellProps> = ({
 itemWidth,
 id,
 booking,
 longBooking,
 index,
 day,
 isToday,
 isSelected,
 isWeekend,
 price,
 isPrice 
}) => {
  const isLast = day.startOf("day") < moment().startOf('day') ? true : false
  return(
    <div className={classNames(styles.tableGrid,{  
      [styles.weekendGrid]:isWeekend,
      [styles.todayGrid]:isToday,
      [styles.selectedGrid]:isSelected
      
      })} 
      id={`id${id}${day.unix()}`}
      style={{
        width:itemWidth                  
      }} 
    >
    {booking && !isPrice &&
      <CheckerboardBanner 
        booking={booking} 
        cellWidth={itemWidth} 
        key={index}
        currentDay={day}
        type="start"
      />}
    {longBooking && !isPrice &&
      <CheckerboardBanner 
        booking={longBooking} 
        cellWidth={itemWidth} 
        key={index}
        currentDay={day}
        type="ending"       
      />}
      {isPrice && price &&
        <PriceBanner
          cellWidth={itemWidth}
          price = {price}
          isLast = {isLast}
        />}
  </div>    
  )
}

export default CheckerboardCell;