import { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import {format} from "date-fns"
import { useDispatch, useSelector } from "react-redux";
import { NotificationIcon } from "../../assets/icons";
import { BookingSelectors, getBookingList, getChessObjects } from "../../redux/reducers/bookingSlice";
import { useClickOutside } from "../../utils/functions";
import { getBookingStatusName } from "../Booking/functions";

import styles from "./NotificationSign.module.scss";

type NoteProp = {
  color?:string
}

const NotificationSign = ({color}:NoteProp) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getBookingList({}))    
  }, []);
  useEffect(() => {
    dispatch(getChessObjects({}))    
  }, []);
  const objects = useSelector(BookingSelectors.getChessObjects);
  const bookingArray = useSelector(BookingSelectors.getBookingList);  
  const filteredList = bookingArray.filter(item=> !item.viewed).sort((a,b)=>{  //сортируем по дате создания
    const f = new Date(a.created_at).getTime();  
    const s = new Date(b.created_at).getTime();        
  return s-f
  }) 
  const countNotViewed = filteredList.length
  const displayCount = countNotViewed>99?"99+" : countNotViewed

  const [isOpenList, setIsOpenList] = useState<boolean>(false)
  const openList = () => {
    setIsOpenList(!isOpenList)
  }
  const menuRef = useRef(null);
  const btnRef = useRef(null);
  
  useClickOutside(menuRef, btnRef, () => setIsOpenList(false)); 
  const countTitle = countNotViewed%10 ===1? "новое уведомление"
  : countNotViewed%10<5 ? "новых уведомления"
  : "новых уведомлений"
  
  return(
    <div className={styles.container} onClick={openList}>
      <div ref={btnRef}>
        <NotificationIcon fill={color}/>
      </div>
      {countNotViewed>0 &&
      <div className={styles.count} >
        {displayCount}
      </div>}
      {/* {isOpenList && countNotViewed>0 &&
      <div className={styles.popup_container} ref={menuRef}>
        <div className={styles.popup_header}>
          У вас {countNotViewed}&ensp;{countTitle}
        </div>
        <div className={styles.popup_list}>
          {filteredList.map(item=> {
            let objectName:string="";
            objects&&objects.map(obj => {
              if (obj.rental_objects.length>0){
                const ind = obj.rental_objects.find(rent=> rent.id === item.rental_object)
                if(ind) objectName=ind.name
              }             
            });
            const statusName = getBookingStatusName(item.status)
            const bookingClassname = item.status==="new"? "new"
              : item.status==="awt"? "await"
              : +item.total===+item.paid? "full_paid" 
              : +item.paid>0? "part_paid"
              : "not_paid";
            return(
              <div className={styles.item_wrap}>
                <div className={styles.item_name}>{objectName}</div>
                <div className={classNames(styles.item_status, styles[bookingClassname])}>
                  {statusName}
                </div>
                <div className={styles.item_main}>
                  {item.last_name} {item.first_name} {item.second_name}, &ensp;
                  { format(item.check_in_date, "dd.MM.yyyy")}-{format(item.check_out_date, "dd.MM.yyyy")},&ensp;
                  {item.total} BYN
                </div>
                <div className={styles.item_create}>
                  {format(item.created_at, "dd.MM.yyyy")} в {format(item.created_at, "H:m")}
                </div>
              </div>
            )})
          }
        </div>
      </div>} */}
    </div>
  )
}

export default NotificationSign;